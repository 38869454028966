import { Component, Input, OnInit } from '@angular/core'
import { SafeResourceUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { UserStore } from 'src/app/modules/core/auth/store/user.store'
import { FreestayInfoModalComponent } from '../freestay-info-modal/freestay-info-modal.component'

@Component({
  selector: 'app-free-stay-summary',
  templateUrl: './free-stay-summary.component.html',
  styleUrls: ['./free-stay-summary.component.sass'],
})
export class FreeStaySummaryComponent implements OnInit {
  profilePic: SafeResourceUrl = 'app/assets/pp_180x180.png'
  fullName: string = ''
  email: string = ''
  // isSubscribed: boolean = false
  @Input() maxQuota: number = 10
  @Input() remainingQuota = 4
  @Input() isSubscribed: boolean
  @Input() onOpenRedeemForm = () => {}

  constructor(
    private userStore: UserStore,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.userStore.getUser()

    this.userStore.userState.subscribe(res => {
      if (
        res != null &&
        res.user.avatar_url != null &&
        res.user.avatar_url.toString() != ''
      ) {
        this.profilePic = res.user.avatar_url
      }
      this.email = res?.user.email
      this.fullName = res?.user.name
    })
  }

  ngOnDestroy(): void {}

  onSubscribe = () => {
    this.router.navigate(['/has-services'])
  }

  onClickFreestaysInfo = () => {
    this.modalService.open(FreestayInfoModalComponent)
  }
}
