import { Component, OnInit } from '@angular/core'
import {
  BookingByMonth,
  FreestaySummaryApiRes,
  MassagedBookingByMonth,
} from 'src/app/modules/shared/models/freestay.model'
import { FreestayService } from 'src/app/modules/shared/service/freestay.service'
import { SecuredStore } from '../../../store/secured.store'
import { Utility } from 'src/app/modules/shared/utility/utility'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-free-stays',
  templateUrl: './free-stays.component.html',
  styleUrls: ['./free-stays.component.sass'],
})
export class FreeStaysComponent implements OnInit {
  isEligible: boolean = false
  freeStayQuota: number = 0
  remainingFreeStayQuota: number = 0
  bookingsPerMonth: MassagedBookingByMonth[] = []
  freestaySummaryId: string = ''

  private readonly _summaryIdSource = new BehaviorSubject<string>('')
  readonly summaryIdState = this._summaryIdSource.asObservable()

  constructor(
    private freestayService: FreestayService,
    private securedStore: SecuredStore,
    private utility: Utility,
    private router: Router
  ) {
    this.securedStore.updatePageTitle('Free Stay')
  }

  ngOnInit(): void {
    this.freestayService
      .getFreestaySummary()
      .then((res: FreestaySummaryApiRes) => {
        this._summaryIdSource.next(res.data.freestay_summary_id)
        this.isEligible = res.data.is_eligible
        this.freeStayQuota = res.data.free_stay_quota
        this.remainingFreeStayQuota = res.data.remaining_free_stay_quota
        res.data.bookings_by_month.forEach(bkByMonth => {
          this.bookingsPerMonth.push(
            new MassagedBookingByMonth(bkByMonth, this.utility)
          )
        })
      })
      .catch(err => {
        console.log(err)
        this.isEligible = false
      })
      .finally(() => {})

    this.summaryIdState.subscribe(val => {
      this.freestaySummaryId = val
    })
  }

  onRedirectRedeem = () => {
    this.router.navigate(['/free-stays/redemption'], {
      queryParams: {
        freestaySummaryId: this.freestaySummaryId,
      },
    })
  }

  onRedirectDetails = (id: string) => {
    this.router.navigate(['/free-stays/details'], {
      queryParams: {
        detailsId: id,
      },
    })
  }
}
