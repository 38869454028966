<div class="content">
  <div class="form-card">
    <form [formGroup]="freestayForm" class="freestay-form">
      <div class="row">
        <div class="col-12 mb-3">
          <custom-input
            [formGroup]="freestayForm"
            label="Choose Project"
            id="project"
            name="project"
            type="select"
            placeholder="Choose Project"
            [valueList]="projectList"
            [handleOnChange]="handleOnChangeProject"></custom-input>
        </div>
        <div class="col-12 mb-3">
          <custom-input
            [formGroup]="freestayForm"
            label="Choose Property"
            id="unit"
            name="unit"
            type="select"
            placeholder="Choose Property"
            [valueList]="propertyList"
            [handleOnChange]="handleOnChangeUnit"></custom-input>
        </div>
        <div class="col-12 col-md-9">
          <custom-input
            [formGroup]="freestayForm"
            id="date_range"
            name="date_range"
            label="Choose Date"
            placeholder="Check in - Check out"
            type="daterangepicker"></custom-input>
        </div>
        <div class="col-12 col-md-3 btn-container">
          <button
            class="btn btn-primary redeem-btn display-3"
            (click)="onRequest()">
            Request
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
