<div class="occupancy-rate bg-white">
  <div class="title">Occupancy Rate</div>
  <div class="text-center value-container mt-3">
    <span class="value">{{ occupancyRate?.current_month }} %</span>
  </div>
  <div
    *ngIf="occupancyRate?.prev_month >= 0 && isShowPrev"
    class="text-center trends"
    [ngClass]="{
      up: occupancyRate?.current_month >= occupancyRate?.prev_month,
      down: occupancyRate?.current_month < occupancyRate?.prev_month
    }">
    <img
      src="app/assets/green-triangle.svg"
      class="me-1"
      *ngIf="
        occupancyRate?.current_month > occupancyRate?.prev_month;
        else redTriangle
      " />
    <ng-template #redTriangle>
      <img
        src="app/assets/red-triangle.svg"
        class="me-1"
        *ngIf="occupancyRate?.current_month < occupancyRate?.prev_month" />
    </ng-template>
    {{ occupancyRate?.prev_month }}
  </div>
</div>
