<div class="content">
  <div
    class="upper-content"
    [ngClass]="{
      processing: stat == 'Processing',
      unavailable: stat == 'Not Available',
      approved: stat == 'Approved',
      cancelled: stat == 'Cancelled',
      complete: stat == 'Complete'
    }">
    <h1 class="status">{{ stat }}</h1>
    <h2 class="status">on {{ statusDate }}</h2>
  </div>
  <div class="unavailable-container" *ngIf="stat == 'Not Available'">
    <span class="unavailable-msg display-standard">
      We regret to inform you that your request has been cancelled as the
      property has already been booked for the same date. Kindly consider
      choosing an alternative date or exploring other properties from the
      selection.
    </span>
  </div>
  <div class="cancelled-container" *ngIf="stat == 'Cancelled'">
    <span class="cancelled-msg display-standard"
      >Your booking was successfully cancelled.</span
    >
  </div>
  <div
    class="row approved-summary"
    *ngIf="stat == 'Approved' || stat == 'Complete'">
    <div class="col-12 col-md-6">
      <span class="display-3">Booking ID : {{ booking?.booking_id }}</span>
      <span class="display-3">Room Number : {{ booking?.property?.name }}</span>
      <!-- <span class="display-3">Room Type : </span> -->
    </div>
    <div class="col-12 col-md-6 right-side">
      <span class="display-3">Name : {{ booking?.booker?.name }}</span>
      <span class="display-3"
        >Contact Number : {{ booking?.booker?.phone }}</span
      >
    </div>
  </div>
  <div class="card-container">
    <div class="booking-summary-container">
      <div class="building-details">
        <h2 class="property-name">
          {{ booking?.property?.project.name }}
        </h2>
        <h3 class="subdetail">{{ booking?.property?.name }}</h3>
      </div>
      <div class="checkin-details">
        <div class="checkinout-container">
          <h3 class="title">Check-in</h3>
          <h2 class="date">{{ checkinDate }}</h2>
          <h2 class="hour">
            {{ checkinTime }}
          </h2>
        </div>
        <div class="vertical-separator"></div>
        <div class="checkinout-container">
          <h3 class="title">Check-out</h3>
          <h2 class="date">
            {{ checkoutDate }}
          </h2>
          <h2 class="hour">
            {{ checkoutTime }}
          </h2>
        </div>
        <div class="vertical-separator"></div>
        <div class="nights-container">
          <img src="app/assets/nights-icon.svg" />
          <h4 class="nights-detail">
            {{ nights }}
          </h4>
        </div>
      </div>
    </div>
    <div class="status-n-btn">
      <button
        class="btn view-detail-btn display-3"
        *ngIf="stat == 'Processing'"
        (click)="cancelBooking(2)"
        [disabled]="cancelStatus != 0 || isLoading">
        {{ cancelStatus == 0 ? "Cancel Request" : "Request Cancelled" }}
      </button>
    </div>
  </div>
  <div class="bottom-approved-container" *ngIf="stat == 'Approved'">
    <div class="row">
      <div class="col-12 col-md-8" style="padding-right: 0 !important">
        <div class="cancellation-section">
          <h2>Cancellation Policy</h2>
          <p class="display-3">
            Cancellation is permitted up to 2 days before the date of booking.
            However, any cancellations made within 2 days of the booking date
            will result in a deduction from your free stay quota. Please be
            aware that cancellations cannot be made after this 2-day window.
          </p>
          <div class="cancel-btn-container">
            <span class="display-3"
              >Cancellation only can be made before <b>{{ cancellationDate }}</b
              >.</span
            >
            <button
              class="btn display-3 cancel-btn"
              (click)="cancelBooking(1)"
              [disabled]="cancelStatus != 0 || isLoading">
              {{ cancelBtnMessage }}
            </button>
          </div>
        </div>
        <div class="how-to-redeeem-section">
          <h2>How to redeem?</h2>
          <div class="howtoitem-container">
            <div class="number-container">1</div>
            <span class="display-3">
              Download or take a screenshot of the booking confirmation before
              arriving at the homestay/hotel.
            </span>
          </div>
          <div class="howtoitem-container">
            <div class="number-container">2</div>
            <span class="display-3">
              Go to hotel lobby and show this page. Please provide a valid form
              of identification, such as a passport or driver’s license.
            </span>
          </div>
          <div class="howtoitem-container">
            <div class="number-container">3</div>
            <span class="display-3">
              Once the keys or keycard are handed to you, you will have the
              necessary access to enter the rooms.
            </span>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-4"
        style="padding-left: 0 !important; height: 100%">
        <div class="help-n-support-section">
          <h2>Help & Support</h2>
          <span class="display-3"
            >Get in touch if you encounter any issues with FreeStay or have any
            questions. Rest assured, we will respond as promptly as
            possible.</span
          >
          <div class="wa-btn-container">
            <button
              class="btn btn-primary whatsapp-btn display-3"
              (click)="redirectWhatsapp()">
              <img src="app/assets/wa-logo.svg" />
              Whatsapp
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
