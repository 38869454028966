<div class="content">
  <div class="upper-content">
    <div class="building-details">
      <h2 class="property-name">{{ projectName }}</h2>
      <h3 class="subdetail">{{ unitNumber }}</h3>
    </div>
    <div class="checkin-details">
      <div class="nights-container">
        <img src="app/assets/nights-icon.svg" />
        <h4 class="nights-detail">{{ nights }}</h4>
      </div>
      <div class="vertical-separator"></div>
      <div class="checkinout-container">
        <h3 class="title">Check-in</h3>
        <h2 class="date">{{ checkinDate }}</h2>
        <h2 class="hour">{{ checkinTime }}</h2>
      </div>
      <div class="vertical-separator"></div>
      <div class="checkinout-container">
        <h3 class="title">Check-out</h3>
        <h2 class="date">{{ checkoutDate }}</h2>
        <h2 class="hour">{{ checkoutTime }}</h2>
      </div>
    </div>
  </div>
  <div class="horizontal-separator"></div>
  <div class="lower-content">
    <h1>Cancellation Policy</h1>
    <span class="display-3"
      >Cancellation is permitted up to 2 days before the date of booking.
      However, any cancellations made within 2 days of the booking date will
      result in a deduction from your free stay quota. Please be aware that
      cancellations cannot be made after this 2-day window.</span
    >
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="freestay-req-confirm"
        [attr.checked]="isConsentTicked ? 'true' : null"
        (change)="toggleCheckbox()" />
      <label class="display-3">
        By ticking this box, I certify that i have read and accept the Term of
        use and cancellation policy above and understand the Rules for my
        reservation.
      </label>
    </div>
    <div class="btn-container">
      <button
        class="btn btn-primary request-btn display-3"
        [attr.disabled]="isConsentTicked == false ? '' : null"
        (click)="onConfirm()">
        Confirm Request
      </button>
    </div>
  </div>
</div>
