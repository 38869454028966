import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-renovation-status-basic-card',
  templateUrl: './renovation-status.component.html',
  styleUrls: ['./renovation-status.component.sass'],
})
export class RenovationStatusBasicCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
