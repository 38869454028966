<div class="freestay-main-container">
  <span class="display-1 activity-title">Activity</span>
  <div class="list-container">
    <div
      *ngIf="
        bookingsPerMonth && bookingsPerMonth.length > 0;
        else noBookingView
      ">
      <div *ngFor="let bookingMonth of bookingsPerMonth">
        <h1 class="month-year-title">{{ bookingMonth.month_year }}</h1>
        <div *ngFor="let booking of bookingMonth.bookings">
          <div class="card-container">
            <div class="booking-summary-container">
              <div class="building-details">
                <h2 class="property-name">
                  {{ booking.property.project.name }}
                </h2>
                <h3 class="subdetail">
                  {{ booking.property.name }}
                </h3>
              </div>
              <div class="checkin-details">
                <div class="checkinout-container">
                  <h3 class="title">Check-in</h3>
                  <h2 class="date">{{ booking.checkin_date }}</h2>
                  <h2 class="hour">{{ booking.checkin_hour }}</h2>
                </div>
                <div class="vertical-separator"></div>
                <div class="checkinout-container">
                  <h3 class="title">Check-out</h3>
                  <h2 class="date">{{ booking.checkout_date }}</h2>
                  <h2 class="hour">{{ booking.checkout_hour }}</h2>
                </div>
                <div class="vertical-separator"></div>
                <div class="nights-container">
                  <img src="app/assets/nights-icon.svg" />
                  <h4 class="nights-detail">{{ booking.nights }}</h4>
                </div>
              </div>
            </div>
            <div class="status-n-btn">
              <div
                class="status-container"
                [ngClass]="{
                  processing: booking.status == 'Processing',
                  unavailable: booking.status == 'Not Available',
                  approved: booking.status == 'Approved',
                  cancelled: booking.status == 'Cancelled',
                  complete: booking.status == 'Complete'
                }">
                <span class="status display-3">{{ booking.status }}</span>
              </div>
              <button
                class="btn btn-primary view-detail-btn display-3"
                (click)="onRedirectDetails(booking.id)">
                View Detail
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #noBookingView>
      <div class="card-container">
        <span>No Activity Yet</span>
      </div>
    </ng-template>
  </div>
</div>
