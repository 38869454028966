import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { FreestayReq } from 'src/app/modules/shared/models/freestay.model'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'
import { FreestayService } from 'src/app/modules/shared/service/freestay.service'
import { Utility } from 'src/app/modules/shared/utility/utility'
import { CustomModalService } from 'src/app/modules/shared/service/custom-modal.service'

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.sass'],
})
export class BookingConfirmationComponent implements OnInit {
  requestPayload: FreestayReq
  isConsentTicked: boolean = false
  projectName: string = ''
  unitNumber: string = ''
  checkinDate: string = ''
  checkoutDate: string = ''
  checkinTime: string = '3:00 pm'
  checkoutTime: string = '12:00 pm'
  nights: string = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private freestayService: FreestayService,
    private utility: Utility,
    private toastService: CustomToastService,
    private customModalService: CustomModalService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap

    this.projectName = queryParams.get('project_name')
    this.unitNumber = queryParams.get('unit_name')

    this.requestPayload = {
      freestay_summary_id: queryParams.get('freestay_summary_id'),
      torus_property_id: queryParams.get('torus_property_id'),
      checkin_time: queryParams.get('checkin_time'),
      checkout_time: queryParams.get('checkout_time'),
    }

    let checkin = new Date(queryParams.get('checkin_time'))
    let checkout = new Date(queryParams.get('checkout_time'))

    this.checkinDate = this.utility.formatDayString(checkin)
    this.checkoutDate = this.utility.formatDayString(checkout)
    // this.checkinTime = this.utility.formatAMPM(checkin)
    // this.checkoutTime = this.utility.formatAMPM(checkout)
    this.nights = this.utility.daysCalc(checkin, checkout)
  }

  toggleCheckbox = () => {
    this.isConsentTicked = !this.isConsentTicked
  }

  onConfirm = () => {
    this.freestayService
      .addRequest(this.requestPayload)
      .then(res => {
        this.customModalService.ShowBasicModal(
          'Request Submitted',
          'Your free stay request has been succesfully summitted. Our team will be processing within 2 working days. Thank you.',
          'Ok',
          '/free-stays'
        )
      })
      .catch(err => console.log('Error : ', err))
      .finally(() => {})
  }
}
