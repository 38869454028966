import { Component, Input, OnInit } from '@angular/core'
import { ControlContainer, FormControl, FormGroup } from '@angular/forms'
import { NgbCalendar, NgbDate, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject, Observable } from 'rxjs'
import { Option } from '../../factory/option.factory'
import { Utility } from '../../utility/utility'

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.sass'],
})
export class CustomInputComponent implements OnInit {
  public form: FormGroup
  public control: FormControl

  @Input() disableFieldsState: Observable<boolean>

  @Input() id: string
  @Input() label: string = ''
  @Input() name: string
  @Input() type: string
  @Input() placeholder: string = ''
  @Input() customErrorMessage: string
  @Input() disableErrorMessage: boolean = false
  @Input() disableField: boolean = false
  @Input() mandatory: boolean = false
  @Input() keyUpFunc: Function
  @Input() handleOnChange: Function

  // Text Area
  textAreaLength: number = 0
  @Input() maxLength: number = 0

  //Date Field
  minDate = {
    year: new Date().getFullYear() - 80,
    month: new Date().getMonth(),
    day: new Date().getDate(),
  }
  maxDate = {
    year: new Date().getFullYear() - 20,
    month: new Date().getMonth(),
    day: new Date().getDate(),
  }
  defaultDateValue: string

  // Timepicker
  timeData: NgbTimeStruct = {
    hour: 0,
    minute: 0,
    second: 0,
  }
  showTimepicker: boolean = false

  // date range picker
  showDateRangepicker: boolean = false
  hoveredDate: NgbDate | null = null
  fromDate: NgbDate
  toDate: NgbDate | null = null
  dateRangeValue: String
  nights: string
  private readonly _nightsSource = new BehaviorSubject<string>('0 Night')
  readonly nightsState = this._nightsSource.asObservable()
  customInputForm = new FormGroup({
    timeControl: new FormControl(this.timeData),
    dateRangeControl: new FormControl(),
  })

  @Input() valueList: Option[]

  generateErrorMessage = label => {
    return {
      required: `${label} is required`,
      email: `Please provide a properly formatted email address`,
      icNumber: `Please provide a properly formatted IC number`,
    }
  }
  errorMessage: string

  constructor(
    private controlContainer: ControlContainer,
    private calendar: NgbCalendar,
    private utility: Utility
  ) {
    this.fromDate = calendar.getToday()
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 2)
    this.calculateNights()
  }

  ngOnInit(): void {
    if (this.disableFieldsState && !this.disableField) {
      this.disableFieldsState.subscribe(data => {
        this.disableField = data
      })
    }

    this.form = <FormGroup>this.controlContainer.control
    this.control = <FormControl>this.form.get(this.name)
    if (this.control.errors) {
      this.errorMessage = this.generateErrorMessage(this.label)[
        Object.keys(this.control.errors)[0]
      ]
    }

    this.control.valueChanges.subscribe(val => {
      if (this.control.errors) {
        this.errorMessage = this.generateErrorMessage(this.label)[
          Object.keys(this.control.errors)[0]
        ]
      }

      if (this.type == 'date' && !this.defaultDateValue) {
        this.defaultDateValue = this.control.value
      }

      if (this.type == 'textarea') {
        this.textAreaLength = this.control.value.length
      }
    })

    this.nightsState.subscribe(nightsValue => {
      this.nights = nightsValue
    })
  }

  onDateSelected(dateObj: NgbDate) {
    this.control.setValue(
      `${dateObj.year}-${
        dateObj.month < 10 ? '0' + dateObj.month : dateObj.month
      }-${dateObj.day < 10 ? '0' + dateObj.day : dateObj.day}`
    )
  }

  onKey(event) {
    if (this.keyUpFunc && event.keyCode == 13) {
      this.keyUpFunc()
    }
  }

  onChange() {
    if (this.handleOnChange) {
      this.handleOnChange(this.control.value)
    }
  }

  onToggleTimepicker() {
    this.showTimepicker = !this.showTimepicker
  }

  onSelectTime() {
    this.timeData = this.customInputForm.get('timeControl').value
    let newTime =
      (this.timeData.hour < 10
        ? '0' + this.timeData.hour
        : this.timeData.hour) +
      ':' +
      (this.timeData.minute < 10
        ? '0' + this.timeData.minute
        : this.timeData.minute) +
      ':00'
    this.control.setValue(newTime)
    this.showTimepicker = false
  }

  // date range related
  onToggleDateRangepicker() {
    this.showDateRangepicker = !this.showDateRangepicker
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date
    } else {
      this.toDate = null
      this.fromDate = date
    }

    this.calculateNights()
  }

  calculateNights() {
    if (this.fromDate && this.toDate) {
      let jsFromDate = new Date(
        this.fromDate.year,
        this.fromDate.month - 1,
        this.fromDate.day
      )
      let jsToDate = new Date(
        this.toDate.year,
        this.toDate.month - 1,
        this.toDate.day
      )

      console.log(this.utility.daysCalc(jsFromDate, jsToDate))

      this._nightsSource.next(this.utility.daysCalc(jsFromDate, jsToDate))
    }
  }

  onSelectDateRange() {
    let jsFromDate = new Date(
      this.fromDate.year,
      this.fromDate.month - 1,
      this.fromDate.day
    )
    let jsToDate = new Date(
      this.toDate.year,
      this.toDate.month - 1,
      this.toDate.day
    )

    let newTime = [jsFromDate, jsToDate]

    this.dateRangeValue =
      this.utility.formatDayString(jsFromDate) +
      ' - ' +
      this.utility.formatDayString(jsToDate)

    this.control.setValue(newTime)
    this.showDateRangepicker = false
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    )
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    )
  }
  // date range related
}
