import { Directive, ElementRef, Input } from '@angular/core'

@Directive({
  selector: '[appModalContent]',
})
export class ModalContentDirective {
  @Input() title: string
  @Input() desc: string

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.innerHTML = `
    <div>
      <h1 style="font-size: 18px !important; font-weight: 700">${this.title}</h1>
      <p style="font-size: 12px !important; font-weight: 400; color: #808080">${this.desc}</p>
    </div>`
  }
}
