import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.sass'],
})
export class SplashComponent implements OnInit {
  showSplash = true

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showSplash = false
    }, 2000)
  }
}
