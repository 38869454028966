<div class="modal-container">
  <div class="modal-close" (click)="onClose()">x</div>
  <div class="modal-header">
    <div class="modal-title display-1" id="staticBackdropLabel">
      Cancel Booking
    </div>
  </div>
  <div class="modal-body">
    <h3>
      Your Free Stay day will not be deducted. You can redeem another free stay
      within the next 24 hours. Would you like to cancel the booking?
    </h3>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onClickCancel()"
      [disabled]="isCancelClicked">
      <span class="white">Cancel</span>
    </button>
    <div
      class="undo-container"
      (click)="onUndoCancellation()"
      *ngIf="isCancelClicked">
      <h4 class="undo-label">Undo</h4>
      <div class="undo-timer">
        <h4>{{ timer }}</h4>
      </div>
    </div>
  </div>
</div>
