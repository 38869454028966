<div class="modal-container">
  <div
    *ngIf="MsgDesc != ''"
    appModalContent
    [title]="MsgTitle"
    [desc]="MsgDesc"
    class="msg-container"></div>
  <div class="modal-close" (click)="activeModal.close()">x</div>
  <div class="modal-header">
    <div class="modal-title" id="staticBackdropLabel">
      <ng-content select="[modalTitle]"></ng-content>
    </div>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <div *ngIf="MsgBtn != ''; else defBtn">
      <button
        type="button"
        class="btn btn-primary basic-btn"
        (click)="onClickOk()">
        <span class="white">{{ MsgBtn }}</span>
      </button>
    </div>
    <ng-template #defBtn>
      <button type="button" class="btn btn-primary" (click)="onClickSubmit()">
        <ng-content select="[modalBtn]"></ng-content>
      </button>
    </ng-template>
  </div>
</div>
