import { Component, Input, OnInit } from '@angular/core'
import {
  BookingByMonth,
  MassagedBookingByMonth,
} from 'src/app/modules/shared/models/freestay.model'

@Component({
  selector: 'app-free-stay-redeemed',
  templateUrl: './free-stay-redeemed.component.html',
  styleUrls: ['./free-stay-redeemed.component.sass'],
})
export class FreeStayRedeemedComponent implements OnInit {
  @Input() bookingsPerMonth: MassagedBookingByMonth[]
  @Input() onRedirectDetails = () => {}

  constructor() {}

  ngOnInit(): void {}
}
