<div class="content">
  <div>
    <app-free-stay-summary
      [isSubscribed]="isEligible"
      [maxQuota]="freeStayQuota"
      [remainingQuota]="remainingFreeStayQuota"
      [onOpenRedeemForm]="onRedirectRedeem"></app-free-stay-summary>
  </div>
  <div class="mt-3">
    <app-free-stay-redeemed
      [bookingsPerMonth]="bookingsPerMonth"
      [onRedirectDetails]="onRedirectDetails"></app-free-stay-redeemed>
  </div>
</div>
