<div class="total-nominal bg-white">
  <div class="title">{{ type }}</div>
  <div class="text-center value-container mt-3">
    <span class="value">{{
      unit?.current_month || "0" | currency: "MYR ":"symbol":"1.2"
    }}</span>
  </div>
  <div
    *ngIf="unit?.current_month >= 0 && unit?.prev_month >= 0 && isShowPrev"
    class="text-center trends"
    [ngClass]="{
      up: unit?.current_month >= unit?.prev_month,
      down: unit?.current_month < unit?.prev_month
    }">
    <img
      src="app/assets/green-triangle.svg"
      class="me-1"
      *ngIf="unit?.current_month > unit?.prev_month; else redTriangle" />
    <ng-template #redTriangle>
      <img
        src="app/assets/red-triangle.svg"
        class="me-1"
        *ngIf="unit?.current_month < unit?.prev_month" />
    </ng-template>
    {{ unit?.prev_month | currency: "MYR ":"symbol":"1.2" }}
  </div>
</div>
