<div class="splash-container" [ngClass]="{ closed: !showSplash }">
  <div class="splash-wrapper">
    <div class="center-fixed">
      <img class="animated-logo" src="app/assets/animated-logo.gif" />
      <img class="beta-logo" src="app/assets/beta-logo.svg" />
    </div>
    <img
      src="app/assets/auth-background-pattern-mobile.svg"
      class="background-pattern" />
  </div>
</div>
