import { Component, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-freestay-info-modal',
  templateUrl: './freestay-info-modal.component.html',
  styleUrls: ['./freestay-info-modal.component.sass'],
})
export class FreestayInfoModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
