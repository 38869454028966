<div class="modal-container">
  <div class="modal-close" (click)="activeModal.close()">x</div>
  <div class="modal-header">
    <div class="modal-title display-2" id="staticBackdropLabel">
      What is Free Stay?
    </div>
  </div>
  <div class="modal-body">
    <h2>
      Free Stay is reward you can claim from HostAStay after you subscribe our
      plan. Reward will received a free stay at your own property that HostAStay
      will offer. Term and condition apply.
    </h2>
    <span class="display-standard">Limits</span>
    <h2>This reward can only be claimed once per account.</h2>
    <span class="display-standard">Notes</span>
    <h2>
      <ol>
        <li>Request will be processing within 24-hours working days.</li>
        <li>Days for free stay are deducted upon redemption.</li>
        <li>Points are non-refundable.</li>
        <li>Redemptions are only available to redeem at booking.hostastay.</li>
      </ol>
    </h2>
    <span class="display-standard">Cancellation Policy</span>
    <h2>
      Cancellation is permitted up to 2 days before the date of booking.
      However, any cancellations made within 2 days of the booking date will
      result in a deduction from your free stay quota. Please be aware that
      cancellations cannot be made after this 2-day window.
    </h2>
  </div>
</div>
