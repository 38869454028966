import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import {
  FreestayCancelResponse,
  FreestayDetails,
  FreestayDetailsApiRes,
} from 'src/app/modules/shared/models/freestay.model'
import { FreestayService } from 'src/app/modules/shared/service/freestay.service'
import { Utility } from 'src/app/modules/shared/utility/utility'
import { environment } from 'src/environments/environment'
import { CancelBookingModalComponent } from '../../component/cancel-booking-modal/cancel-booking-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { CustomToastService } from 'src/app/modules/shared/service/custom-toast.service'

@Component({
  selector: 'app-free-stays-detail',
  templateUrl: './free-stays-detail.component.html',
  styleUrls: ['./free-stays-detail.component.sass'],
})
export class FreeStaysDetailComponent implements OnInit {
  freestayId: string
  stat: string = 'Processing'
  cancel_stat: string = 'none'
  booking: FreestayDetails
  checkinDate: string
  checkoutDate: string
  checkinTime: string
  checkoutTime: string
  nights: string
  statusDate: string
  cancelStatus: number
  cancellationDate: string
  cancelBtnMessage: string
  isloadingCancel: boolean = false

  readonly _statusSource = new BehaviorSubject<string>('Processing')
  readonly statusState = this._statusSource.asObservable()

  readonly _cancelStatusSource = new BehaviorSubject<number>(0)
  readonly cancelStatusState = this._cancelStatusSource.asObservable()

  readonly _cancelBtnMsgSource = new BehaviorSubject<string>('Cancel booking')
  readonly cancelBtnMsgState = this._cancelBtnMsgSource.asObservable()

  readonly _isLoadingCancelSource = new BehaviorSubject<boolean>(false)
  readonly isLoadingCancelState = this._isLoadingCancelSource.asObservable()

  constructor(
    private freestayService: FreestayService,
    private route: ActivatedRoute,
    private utility: Utility,
    private modalService: NgbModal,
    private toastService: CustomToastService
  ) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap
    this.freestayId = queryParams.get('detailsId')

    this.subStatus()
    this.subCancelStatus()
    this.subCancelBtnMsg()
    this.subIsLoadingCancel()

    this.fetchDetails()
  }

  subStatus = () => {
    this.statusState.subscribe(val => {
      this.stat = val
    })
  }

  subCancelStatus = () => {
    this.cancelStatusState.subscribe(val => {
      this.cancelStatus = val
    })
  }

  subCancelBtnMsg = () => {
    this.cancelBtnMsgState.subscribe(val => {
      this.cancelBtnMessage = val
    })
  }

  subIsLoadingCancel = () => {
    this.isLoadingCancelState.subscribe(val => {
      this.isloadingCancel = val
    })
  }

  fetchDetails = () => {
    this.freestayService
      .getFreestayDetails(this.freestayId)
      .then((res: FreestayDetailsApiRes) => {
        // format checkin and checout date
        let checkin = new Date(res.data.checkin_time)
        this.checkinDate = this.utility.formatDayString(checkin)
        this.checkinTime = this.utility.formatAMPM(checkin)
        let checkout = new Date(res.data.checkout_time)
        this.checkoutDate = this.utility.formatDayString(checkout)
        this.checkoutTime = this.utility.formatAMPM(checkout)
        this.nights = this.utility.daysCalc(checkin, checkout)

        // set status string
        switch (res.data.status) {
          case 0:
            this._statusSource.next('Processing')
            break
          case 1:
            this._statusSource.next('Not Available')
            break
          case 2:
            this._statusSource.next('Approved')
            break
          case 3:
            this._statusSource.next('Cancelled')
            break
          case 4:
            this._statusSource.next('Complete')
            break
          default:
            break
        }

        switch (res.data.cancellation_status) {
          case 0:
            this.cancel_stat = 'None'
            break
          case 1:
            this.cancel_stat = 'Cancellation Filed'
            break
          case 2:
            this.cancel_stat = 'Cancelled'
            break
          case 3:
            this.cancel_stat = 'Cancellation Unavailable'
            break
          default:
            break
        }

        // add rest of booking data to local variable
        this.booking = res.data

        // set updated_at date format
        let updatedAtDate = new Date(this.booking.updated_at)
        this.statusDate = this.utility.formatDatetime(updatedAtDate)

        // set initial cancellable
        this.cancelStatus = res.data.cancellation_status

        // cancellation date
        let maxCancel = new Date(this.booking.max_cancellation_date)
        let currentDate: Date = new Date()
        if (this.cancelStatus == 0 && currentDate >= maxCancel) {
          this._cancelBtnMsgSource.next('Cancellation Unavailable')
          this.cancelStatus = 3
        } else if (this.cancelStatus == 1) {
          this._cancelBtnMsgSource.next('Cancellation Submitted')
        }
        this.cancellationDate = this.utility.formatDaystringTime(maxCancel)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  // 1 for filing cancellation, 2 for cancelled right away
  onCancel = (newCancelStatus: number) => {
    this.freestayService
      .cancelBooking(this.freestayId, newCancelStatus)
      .then((res: FreestayCancelResponse) => {
        // refetch data and update state
        this._cancelStatusSource.next(res.new_cancellation_status)

        switch (res.new_status) {
          case 0:
            this._statusSource.next('Processing')
            break
          case 1:
            this._statusSource.next('Not Available')
            break
          case 2:
            this._statusSource.next('Approved')
            break
          case 3:
            this._statusSource.next('Cancelled')
            break
          case 4:
            this._statusSource.next('Complete')
            break
          default:
            break
        }

        if (res.new_cancellation_status == 1) {
          this._cancelBtnMsgSource.next('Cancellation Submitted')
        }

        this.toastService.show('Success', res.message)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this._isLoadingCancelSource.next(false)
      })
  }

  onCloseModal = () => {
    this._isLoadingCancelSource.next(false)
    this._cancelBtnMsgSource.next('Cancel booking')
  }

  cancelBooking = (cancelStatus: number) => {
    this._isLoadingCancelSource.next(true)
    this._cancelBtnMsgSource.next('Submitting...')

    let isStraightforward = false
    if (cancelStatus == 2) {
      isStraightforward = true
    }

    // open cancellation popup
    const cancelModalRef = this.modalService.open(CancelBookingModalComponent)
    cancelModalRef.componentInstance.onCancelFunc = this.onCancel
    cancelModalRef.componentInstance.cancelStatus = cancelStatus
    cancelModalRef.componentInstance.onCloseModal = this.onCloseModal
    cancelModalRef.componentInstance.isStraightforward = isStraightforward
  }

  redirectWhatsapp = () => {
    window.open(environment.companyWhatsappLink, '_blank')
  }
}
