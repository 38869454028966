import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { OccupancyRate } from 'src/app/modules/shared/models/property.model'

@Component({
  selector: 'app-occupancy-rate',
  templateUrl: './occupancy-rate.component.html',
  styleUrls: ['./occupancy-rate.component.sass'],
})
export class OccupancyRateComponent implements OnInit {
  @Input() occupancyRateState: Observable<OccupancyRate>
  occupancyRate: OccupancyRate
  percentage: number
  isShowPrev: boolean = true

  constructor() {}

  ngOnInit(): void {
    this.occupancyRateState.subscribe(data => {
      this.occupancyRate = data
      let currDate = new Date()
      let currYear = currDate.getFullYear()
      let currMonth = currDate.getMonth()
      if (data.selectedYear == currYear && data.selectedMonth == currMonth) {
        this.isShowPrev = true
      } else {
        this.isShowPrev = false
      }
    })
  }
}
