import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Endpoints } from '../enums/endpoints.enum'
import { ApiFactory } from '../factory/api.factory'
import {
  FSPropertiesResponse,
  FreestayReq,
  FreestaySummaryApiRes,
} from '../models/freestay.model'

@Injectable({
  providedIn: 'root',
})
export class FreestayService {
  constructor(private http: HttpClient) {}

  async getFreestaySummary() {
    let endpoint = new ApiFactory(Endpoints.FREESTAY).toString()
    return new Promise((resolve, reject) => {
      this.http
        .get<FreestaySummaryApiRes>(`${environment.baseUrl}${endpoint}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getProperties() {
    let endpoint = new ApiFactory(Endpoints.FS_PROPERTIES).toString()

    return new Promise((resolve, reject) => {
      this.http
        .get<FSPropertiesResponse>(`${environment.baseUrl}${endpoint}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async addRequest(req: FreestayReq) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.baseUrl}${new ApiFactory(Endpoints.FS_ADD)}`, req)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async getFreestayDetails(freestayID: string) {
    let endPoint = Endpoints.FS_DETAILS.replace('[FREESTAY_ID]', freestayID)

    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.baseUrl}${new ApiFactory(endPoint)}`)
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }

  async cancelBooking(freestayID: string, newCancelStatus: number) {
    let endPoint = Endpoints.FS_CANCEL.replace('[FREESTAY_ID]', freestayID)

    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.baseUrl}${new ApiFactory(endPoint)}`, {
          cancellation_status: newCancelStatus,
        })
        .subscribe({
          next: res => {
            resolve(res)
          },
          error: err => {
            reject(err)
          },
        })
    })
  }
}
