<div class="total-booked-nights bg-white">
  <div class="title">Total Booked Nights</div>
  <div class="text-center value-container mt-3">
    <span class="value">{{ totalBooked?.current_month || "0" }}</span>
    <span class="total"> / {{ totalBooked?.days_in_month || "0" }}</span>
  </div>
  <div
    *ngIf="
      totalBooked?.current_month >= 0 &&
      totalBooked?.prev_month >= 0 &&
      isShowPrev
    "
    class="text-center trends"
    [ngClass]="{
      up: totalBooked?.current_month >= totalBooked?.prev_month,
      down: totalBooked?.current_month < totalBooked?.prev_month
    }">
    <img
      src="app/assets/green-triangle.svg"
      class="me-1"
      *ngIf="
        totalBooked?.current_month > totalBooked?.prev_month;
        else redTriangle
      " />
    <ng-template #redTriangle>
      <img
        src="app/assets/red-triangle.svg"
        class="me-1"
        *ngIf="totalBooked?.current_month < totalBooked?.prev_month" />
    </ng-template>
    {{ totalBooked?.prev_month }}/{{ totalBooked?.difference_percentage }}%
  </div>
</div>
