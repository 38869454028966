<div class="summary-container">
  <div class="row d-flex align-items-center">
    <div class="col-12 col-lg-9 d-flex flex-row align-items-center">
      <div class="img-container">
        <img [src]="profilePic" *ngIf="profilePic != ''" class="img-style" />
      </div>
      <div class="info-container" style="margin-left: 1rem">
        <h1 class="display-2" style="margin: 0">{{ fullName }}</h1>
        <h2 class="normal-weight">{{ email }}</h2>
      </div>
    </div>
    <div class="col-12 col-lg-3">
      <div style="text-align: center" *ngIf="isSubscribed">
        <button
          class="btn btn-primary"
          style="text-transform: uppercase"
          (click)="onOpenRedeemForm()">
          Redeem
        </button>
      </div>
    </div>
  </div>
  <div class="row d-flex align-end gy-2" style="margin-top: 1rem">
    <div class="quota-container col-12 col-lg-9">
      <div class="d-flex flex-row" style="align-items: center">
        <h1 class="display-2" style="margin: 0">My Free Stays</h1>
        <div
          class="logo-container"
          style="margin-left: 0.875rem; cursor: pointer"
          (click)="onClickFreestaysInfo()">
          <img src="app/assets/info-icon-blue.svg" />
        </div>
      </div>
      <h2 *ngIf="!isSubscribed" class="normal-weight">
        You haven’t subscribed any package yet.
      </h2>
    </div>
    <div class="col-12 col-lg-3">
      <div style="margin-bottom: 1rem" *ngIf="!isSubscribed">
        <h2 class="normal-weight" style="text-align: center">
          Generate better return now
        </h2>
      </div>
      <div style="text-align: center" *ngIf="!isSubscribed">
        <button class="btn btn-primary" (click)="onSubscribe()">
          Subscribe Now
        </button>
      </div>
      <div *ngIf="isSubscribed" style="text-align: center">
        <span class="quota-style">{{ remainingQuota }}</span>
        <span> / {{ maxQuota }} nights left</span>
      </div>
    </div>
  </div>

  <!-- quota bar -->
  <div class="background-bar" *ngIf="isSubscribed">
    <div
      class="amount-bar"
      [ngStyle]="{ width: (remainingQuota / maxQuota) * 100 + '%' }"></div>
  </div>
</div>
