import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-short-stay-basic-card',
  templateUrl: './short-stay-setup.component.html',
  styleUrls: ['./short-stay-setup.component.sass'],
})
export class ShortStayBasicCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
