import { Utility } from '../utility/utility'

export interface FreestaySummaryApiRes {
  data: FreestaySummary
}

export interface FreestaySummary {
  freestay_summary_id: string
  user_id: string
  is_eligible: boolean
  free_stay_quota: number
  remaining_free_stay_quota: number
  bookings_by_month: BookingByMonth[]
}

export interface BookingByMonth {
  month_year: string
  bookings: Booking[]
}

export interface Booking {
  id: string
  property: UnitBooked
  checkin_time: string
  checkout_time: string
  status: number
  cancellation_status: number
}

export class MassagedBookingByMonth {
  month_year: string
  bookings: MassagedBooking[] = []

  constructor(obj: BookingByMonth, private utility: Utility) {
    this.month_year = obj.month_year
    obj.bookings.forEach(bk => {
      this.bookings.push(new MassagedBooking(bk, utility))
    })
  }
}

export class MassagedBooking {
  id: string
  property: UnitBooked
  checkin_date: string
  checkin_hour: string
  checkout_date: string
  checkout_hour: string
  nights: string
  status: string
  cancellation_status: string

  constructor(obj: Booking, private utility: Utility) {
    let checkin = new Date(obj.checkin_time)
    let checkinDate = this.utility.formatDayString(checkin)
    let checkinTime = this.utility.formatAMPM(checkin)

    let checkout = new Date(obj.checkout_time)
    let checkoutDate = this.utility.formatDayString(checkout)
    let checkoutTime = this.utility.formatAMPM(checkout)

    let stat = 'processing'
    switch (obj.status) {
      case 0:
        stat = 'Processing'
        break
      case 1:
        stat = 'Not Available'
        break
      case 2:
        stat = 'Approved'
        break
      case 3:
        stat = 'Cancelled'
        break
      case 4:
        stat = 'Complete'
        break
      default:
        break
    }

    let cancel_stat = 'none'
    switch (obj.cancellation_status) {
      case 0:
        cancel_stat = 'None'
        break
      case 1:
        cancel_stat = 'Cancellation Filed'
        break
      case 2:
        cancel_stat = 'Cancelled'
        break
      case 3:
        cancel_stat = 'Cancellation Unavailable'
        break
      default:
        break
    }

    this.id = obj.id
    this.property = obj.property
    this.checkin_date = checkinDate
    this.checkin_hour = checkinTime
    this.checkout_date = checkoutDate
    this.checkout_hour = checkoutTime
    this.nights = this.utility.daysCalc(checkin, checkout)
    this.status = stat
    this.cancellation_status = cancel_stat
  }
}

export interface UnitBooked {
  id: string
  name: string
  project: BookedUnitProject
}

export interface BookedUnitProject {
  id: string
  name?: string
  address_1: string
  address_2: string
  state: string
  area: string
  post_code: string
}

export interface FSPropertiesResponse {
  projects: ByProjectRes[]
}

export interface ByProjectRes {
  project_id: string
  project_name: string
  properties: FSProperty[]
}

export interface FSProperty {
  id: string
  unit_number: string
}

export interface FreestayReq {
  freestay_summary_id: string
  torus_property_id: string
  checkin_time: string
  checkout_time: string
}

export interface FreestayDetailsApiRes {
  data: FreestayDetails
}

export interface FreestayDetails {
  id: string
  freestay_summary_id: string
  booker: BookerDetails
  booking_id: string
  property: UnitBooked
  checkin_time: string
  checkout_time: string
  status: number
  cancellation_status: number
  max_cancellation_date: string
  created_at: string
  updated_at: string
}

export interface BookerDetails {
  name: string
  email: string
  phone: string
}

export interface FreestayCancelResponse {
  max_cancelation_date: string
  message: string
  new_status: number
  new_cancellation_status: number
}
