import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { TotalBookedNights } from 'src/app/modules/shared/models/property.model'

@Component({
  selector: 'app-total-booked-nights',
  templateUrl: './total-booked-nights.component.html',
  styleUrls: ['./total-booked-nights.component.sass'],
})
export class TotalBookedNightsComponent implements OnInit {
  @Input() totalBookNightsState: Observable<TotalBookedNights>
  totalBooked: TotalBookedNights
  isShowPrev: boolean = true

  constructor() {}

  ngOnInit(): void {
    this.totalBookNightsState.subscribe(data => {
      this.totalBooked = data
      let currDate = new Date()
      let currYear = currDate.getFullYear()
      let currMonth = currDate.getMonth()
      if (data.selectedYear == currYear && data.selectedMonth == currMonth) {
        this.isShowPrev = true
      } else {
        this.isShowPrev = false
      }
    })
  }
}
