import { Component, OnInit } from '@angular/core'
import { Option } from 'src/app/modules/shared/factory/option.factory'
import { HasServicesStore } from '../../../has-services/pages/store/has-services.store'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { SecuredStore } from '../../../store/secured.store'
import { FreestayService } from 'src/app/modules/shared/service/freestay.service'
import {
  FSPropertiesResponse,
  FreestayReq,
} from 'src/app/modules/shared/models/freestay.model'
import { Utility } from 'src/app/modules/shared/utility/utility'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-free-stays-redemption',
  templateUrl: './free-stays-redemption.component.html',
  styleUrls: ['./free-stays-redemption.component.sass'],
})
export class FreeStaysRedemptionComponent implements OnInit {
  freestaySummaryId: string
  projectList: Option[] = []
  propertyList: Option[] = []
  selectedProject: Option
  selectedUnit: Option
  projectsKV = new Map()
  propertiesKV = new Map()
  propMap = new Map()

  freestayForm = new FormGroup({
    project: new FormControl('', Validators.required),
    unit: new FormControl('', Validators.required),
    date_range: new FormControl([], Validators.required),
  })

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private freestayService: FreestayService,
    private securedStore: SecuredStore,
    private utility: Utility
  ) {
    this.securedStore.updatePageTitle('Free Stay')
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap

    this.freestaySummaryId = queryParams.get('freestaySummaryId')
    // console.log('freestay summary : ', this.freestaySummaryId)

    this.freestayService
      .getProperties()
      .then((res: FSPropertiesResponse) => {
        if (res.projects.length > 0) {
          let projOpt: Option
          res.projects.forEach(proj => {
            projOpt = {
              id: proj.project_id,
              label: proj.project_name,
              value: proj.project_id,
            }
            this.projectList.push(projOpt)
            this.projectsKV.set(proj.project_id, proj.project_name)

            let unitOps = []
            let unitOpt: Option
            proj.properties.forEach(prop => {
              unitOpt = {
                id: prop.id,
                label: prop.unit_number,
                value: prop.id,
              }
              unitOps.push(unitOpt)
              this.propertiesKV.set(prop.id, prop.unit_number)
            })

            this.propMap.set(proj.project_id, unitOps)
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
  }

  handleOnChangeProject = (projectId: string) => {
    this.selectedProject = this.projectList.find(val => {
      return val.id === projectId
    })
    this.propertyList = this.propMap.get(projectId)
  }

  handleOnChangeUnit = (unitId: string) => {
    this.selectedUnit = this.propertyList.find(val => {
      return val.id === unitId
    })
  }

  onRequest = () => {
    let projectId = this.freestayForm.get('project').value
    let unit = this.freestayForm.get('unit').value
    let checkin = this.utility.formatDateOnly(
      this.freestayForm.get('date_range').value[0]
    )
    let checkout = this.utility.formatDateOnly(
      this.freestayForm.get('date_range').value[1]
    )

    this.router.navigate(['/free-stays/booking-confirmation'], {
      queryParams: {
        freestay_summary_id: this.freestaySummaryId,
        project_name: this.projectsKV.get(projectId),
        unit_name: this.propertiesKV.get(unit),
        torus_property_id: unit,
        checkin_time: checkin,
        checkout_time: checkout,
      },
    })
  }
}
