export const environment = {
  production: true,
  baseUrl: 'https://torusapi.hostastay.com/api',
  signeasyBaseUrl: 'https://api.signeasy.com/v2.1',
  signeasyToken: 'Tqx13eZIpRdZW1ufCOR8jk6hIcUXiY',
  googleApiKey:
    '556629300879-num9fpq4ch8unu8vp55o6v9jk7hjv1fv.apps.googleusercontent.com',
  facebookApiKey: '461077588946660',
  sentryDsn:
    'https://8bc5fff9deeb4550b1d06f303f22cf0d@o1220026.ingest.sentry.io/6362727',
  companyWhatsappLink: 'https://wa.me/message/UWHX24MN7HEBD1',
}
